import { ReactNode } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import StnVideoAd from '../ads/StnVideoAd';
import MinuteMediaAd from '../ads/MinuteMediaAd';
import useScreenSize from 'hooks/useScreenSize';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import VermontLeaderboardAd from '../ads/sponsors/TopBannerAds/VermontLeaderboardAd';

export default function WebPage({ children, subNav }: { children: ReactNode; subNav?: ReactNode }) {
  const { isSmallScreen } = useScreenSize();
  const [{ disableAds, bannerAd }] = useApplicationContainer();

  return (
    <>
      <Header subNav={subNav} />
      {(isSmallScreen || !disableAds) && bannerAd == 'vermont_leaderboard' ? <VermontLeaderboardAd /> : <MinuteMediaAd placement="TOP" />}
      {(isSmallScreen || disableAds) && <StnVideoAd placement="TOP" />}
      <main>{children}</main>
      <Footer />
      {isSmallScreen && <MinuteMediaAd placement="BOTTOM" />}
    </>
  );
}
