import PhotoIcon from 'icons/PhotoIcon';
import Link, { BackParam } from './Link';
import styles from './styles/PhotoGalleryTile.module.scss';
import Image from 'components/Image';
import { PhotoGalleriesIndexQuery } from 'queries';

export interface PhotoGalleryTileProps {
  coverImage: string;
  onClick?: () => void;
  genderSport: string;
  teams: PhotoGalleriesIndexQuery['galleries']['nodes'][number]['teams'];
  dateText: string;
  galleryCount: number;
  linkUrl: string;
  photographer: {
    firstName: string;
    lastName: string;
  };
  thumbnailUrl: string | undefined;
}

export default function PhotoGalleryTile({
  coverImage,
  onClick,
  teams,
  dateText,
  galleryCount,
  linkUrl,
  photographer,
  thumbnailUrl
}: PhotoGalleryTileProps) {
  const countLabel = galleryCount === 1 ? 'Photo' : 'Photos';
  const firstTeam = `${teams[0].name} ${teams[0].state?.abbrev ? `(${teams[0].state?.abbrev})` : null}`;
  const secondTeam = teams[1] ? `${teams[1].name} ${teams[1].state?.abbrev ? `(${teams[1].state?.abbrev})` : null}` : null;
  return (
    <Link href={linkUrl} onClick={onClick} className={styles.TileContainer} back={BackParam.ADD}>
      <Image src={thumbnailUrl ?? coverImage} alt={`${teams[0].name} cover`} className={styles.CoverImage} />
      <div className={styles.TileDetails}>
        <div className={styles.GalleryDate}>{dateText}</div>
        <div className={styles.TeamText}>{secondTeam ? `${firstTeam} vs. ${secondTeam}` : firstTeam}</div>
        <div className={styles.PhotographerCredit}>
          Photos by {photographer.firstName} {photographer.lastName}
        </div>
      </div>
      <div className={styles.PhotoCount}>
        <div className={styles.PhotoCountDisplay}>
          <PhotoIcon /> {galleryCount} {countLabel}
        </div>
      </div>
    </Link>
  );
}
