import styles from './styles/VermontLeaderboardAd.module.scss';
import VPABannerAd from '../../../../../../assets/images/sponsors/vpa-driving/VPA-banner-ad.png';
import Clickable from '@/components/Clickable';

export default function VermontLeaderboardAd() {
  return (
    <div className={styles.AdContainer}>
      <Clickable href="https://drivewell.vermont.gov/?utm_source=scorebooklive&utm_medium=referral" target="_blank">
        <img src={VPABannerAd} alt="Vermont Leaderboard Ad" className={styles.Ad} />
      </Clickable>
    </div>
  );
}
